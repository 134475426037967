import { faker } from "@faker-js/faker";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import moment from "moment";
import { ModelRegistry } from "./MirageModels";

export function getUserAvailability(): any {
  return {
    id: "00d96f65-1e9f-480e-9ea7-1a37db01c204",
    jobseekerName: "prasanth",
    interviewingJobTitle: "Full Stack",
    interviewRoundSequence: 1,
    selectableTimeSlots: [
      {
        startTime: "09:00",
        endTime: "10:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "10:00",
        endTime: "11:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "11:00",
        endTime: "12:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "12:00",
        endTime: "13:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "13:00",
        endTime: "14:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "14:00",
        endTime: "15:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "15:00",
        endTime: "16:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "16:00",
        endTime: "17:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "17:00",
        endTime: "18:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "18:00",
        endTime: "19:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "19:00",
        endTime: "20:00",
        timeSlotType: "AVAILABLE",
      },
      {
        startTime: "20:00",
        endTime: "21:00",
        timeSlotType: "AVAILABLE",
      },
    ],
    timeSlots: [
      {
        date: moment(new Date()),
        timeSlots: [
          { startTime: "09:00", endTime: "10:00", timeSlotType: "PROVIDED" },
          { startTime: "10:00", endTime: "11:00", timeSlotType: "RESERVED" },
          { startTime: "11:00", endTime: "12:00", timeSlotType: "PROVIDED" },
          { startTime: "12:00", endTime: "13:00", timeSlotType: "AVAILABLE" },
          { startTime: "13:00", endTime: "14:00", timeSlotType: "AVAILABLE" },
          { startTime: "14:00", endTime: "15:00", timeSlotType: "RESERVED" },
          { startTime: "15:00", endTime: "16:00", timeSlotType: "AVAILABLE" },
          { startTime: "16:00", endTime: "17:00", timeSlotType: "RESERVED" },
          { startTime: "17:00", endTime: "18:00", timeSlotType: "PROVIDED" },
          { startTime: "18:00", endTime: "19:00", timeSlotType: "PROVIDED" },
          { startTime: "19:00", endTime: "20:00", timeSlotType: "RESERVED" },
          { startTime: "20:00", endTime: "21:00", timeSlotType: "RESERVED" },
        ],
      },
      {
        date: moment(new Date("2022-09-05 14:13:05")),
        timeSlots: [
          { startTime: "09:00", endTime: "10:00", timeSlotType: "PROVIDED" },
          { startTime: "10:00", endTime: "11:00", timeSlotType: "RESERVED" },
          { startTime: "11:00", endTime: "12:00", timeSlotType: "PROVIDED" },
          { startTime: "12:00", endTime: "13:00", timeSlotType: "AVAILABLE" },
          { startTime: "13:00", endTime: "14:00", timeSlotType: "AVAILABLE" },
          { startTime: "14:00", endTime: "15:00", timeSlotType: "RESERVED" },
          { startTime: "15:00", endTime: "16:00", timeSlotType: "AVAILABLE" },
          { startTime: "16:00", endTime: "17:00", timeSlotType: "RESERVED" },
          { startTime: "17:00", endTime: "18:00", timeSlotType: "PROVIDED" },
          { startTime: "18:00", endTime: "19:00", timeSlotType: "PROVIDED" },
          { startTime: "19:00", endTime: "20:00", timeSlotType: "RESERVED" },
          { startTime: "20:00", endTime: "21:00", timeSlotType: "RESERVED" },
        ],
      },
    ],
  };
}

export const mockGetUserAvailability: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  console.log("here");
  const sampleData = schema.all("userAvailability").models;
  const qp = request.params;

  console.log("Availability", sampleData);

  if (sampleData.length != 0) {
    return new Response(200, {}, sampleData[0].attrs);
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};

export const mockPostUserAvailability: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let userAvailabilityBody = JSON.parse(request.requestBody);
  console.log("userAvailabilityBody", userAvailabilityBody);
  return new Response(200, {}, userAvailabilityBody.id);
};
