import { ButtonProps } from "@idsk/components-props";
import { Button, Result } from "antd";

export const NotFound = ({ btnProps }: { btnProps: ButtonProps }) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={btnProps.onSubmitClick}
          icon={btnProps.icon}
        >
          {btnProps.text}
        </Button>
      }
    />
  );
};
