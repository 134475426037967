import { EnvironmentType, UnAuthEndPointConfig } from "@idsk/b2c-common";

import { createServer } from "miragejs";
import { getFeedback } from "./Feedback";
import {
  getUserAvailability,
  mockGetUserAvailability,
  mockPostUserAvailability,
} from "./UserAvailability";
import {
  getInterview,
  mockGetInterview,
  mockPatchCandidate,
} from "./Interview";
import { ModelRegistry } from "./MirageModels";
import { B2CEndpointConfig, EnvironmentsType } from "@idsk/frontend-dao";
import {
  getUserAvailabilityNew,
  mockGetUserAvailabilityNew,
  mockPutUserAvailability,
} from "./InterviewerAvailability";
import {
  getInterviewerProfileSummaryB2CContent,
  getInterviewerProfileSummaryB2CHeader,
  mockGetInterviewerProfileSummaryHeaderProps,
} from "./InterviewerProfileSummaryResponse";
import {
  getQuestionsForListing,
  mockGetQuestionsForListing,
} from "./QuestionListing";
import {
  getJobProfileV2Calibration,
  mockPatchJobProfileV2,
} from "./CalibrationNotes";

export function makeServer() {
  const environment = (process.env.STAGE as EnvironmentType) || "development";
  return createServer({
    environment,
    models: ModelRegistry,
    routes() {
      this.get(`/questions`, mockGetQuestionsForListing);
      // this.urlPrefix = `${UnAuthEndPointConfig[environment]}/api`;
      this.urlPrefix = `${B2CEndpointConfig[environment]}/api`;

      this.timing = 1000;

      this.get(`/questions`, mockGetQuestionsForListing);
      this.get(`/application`, mockGetInterview);
      this.get(`/jobseeker/availabilities/:token`, mockGetUserAvailability);
      this.put(`/jobseeker/availabilities/:token`, mockPostUserAvailability);
      //user Availability
      this.get(
        "/v1/d2s/userAvailabilitys/clients/:clientId/:userTypes/:id",
        mockGetUserAvailabilityNew
      );
      this.put(
        "/v1/d2s/userAvailabilitys/clients/:clientId/:userTypes/:id",
        mockPutUserAvailability
      );
      this.get(
        `/interviewerProfileSummary`,
        mockGetInterviewerProfileSummaryHeaderProps
      );
      //Calibration Notes
      this.patch(
        "/v2/clients/:clientId/jobProfiles/:id",
        mockPatchJobProfileV2
      );
      //Candidate Notes
      this.patch("/v1/clients/:clientId/applications/:id", mockPatchCandidate);

      this.passthrough(
        (request) =>
          !(
            request.queryParams?.useMirage ??
            request.requestHeaders?.useMirage ??
            false
          )
      );
    },
    seeds(server) {
      for (let i = 0; i < 100; i++) {
        server.create("application", getInterview());
        server.create("feedback", getFeedback());
        server.create("question", getQuestionsForListing());
      }
      server.create("userAvailability", getUserAvailability());
      server.create("interviewerAvailability", getUserAvailabilityNew());

      server.create(
        "interviewerProfileSummaryList",
        getInterviewerProfileSummaryB2CHeader()
      );
      server.create(
        "interviewerProfileSummaryContent",
        getInterviewerProfileSummaryB2CContent()
      );
      server.create("calibrationNotesList", getJobProfileV2Calibration());
    },
  });
}
