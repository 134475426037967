import Iframe from "react-iframe";
import { useParams, useSearchParams } from "react-router-dom";

export const ChimeApp = () => {
  const token = useSearchParams()[0].get("token");
  const { clientId, applicationId, interviewRoundId } = useParams();
  let queryparams = "";
  if (token == null || token.length == 0) {
    queryparams = `clientId=${clientId}&applicationId=${applicationId}&interviewRoundId=${interviewRoundId}`;
  } else {
    queryparams = `token=${token}`;
  }
  console.log("queryparams", queryparams);
  return (
    <Iframe
      url={`/chime-build/?${queryparams}`}
      width="100%"
      height="100%"
      display="block"
    />
  );
};
