import { faker } from "@faker-js/faker";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";

export function getFeedback(): any {
  return {
    applicationId: faker.datatype.uuid(),
    feedbackTemplateClientId: faker.datatype.uuid(),
    feedbackTemplateId: faker.datatype.uuid(),
    id: faker.datatype.uuid(),
    clientId: faker.datatype.uuid(),
    interviewRoundId: faker.datatype.uuid(),
    sections: {
      sectionId: faker.random.numeric(1),
      sectionName: faker.word.noun(),
      additionalNotes: faker.word,
      additionalNotesNeeded: "NEEDED",
      questions: [
        {
          questionId: faker.random.numeric(1),
          questionText: faker.random.words(8),
          questionType: faker.word.noun(),
          providedAnswers: [faker.random.numeric(1)],
          possibleAnswers: [
            {
              answerId: faker.random.numeric(1),
              text: "Yes",
              score: faker.random.numeric(1),
            },
            {
              answerId: faker.random.numeric(1),
              text: "No",
              score: faker.random.numeric(1),
            },
          ],
        },
      ],
    },
  };
}

export const mockGetFeedbackTemplate: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  console.log("here");
  const sampleData = schema.all("feedback").models;
  console.log(sampleData.length);
  const qp = request.params;

  const feedbackTemplate = sampleData.find(
    (item) =>
      (item?.attrs as any)?.feedbackClientId === qp.feedbackTemplateClientId &&
      (item?.attrs as any)?.feedbackTemplateId === qp.feedbackTemplateId
  );
  console.log("Mirage Sample", feedbackTemplate);

  if (feedbackTemplate) {
    return new Response(200, {}, feedbackTemplate);
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};

export const mockPostFeedback: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let feedbackBody = JSON.parse(request.requestBody);
  feedbackBody.feedbackId = "MirageJS_" + Math.floor(Math.random() * 100);
  schema.create("feedbackBody", feedbackBody);
  return new Response(200, {}, feedbackBody.feedbackId);
};
