"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAvailabilitySlot = void 0;
exports.UserAvailabilitySlot = [
    {
        startTime: "09:00",
        endTime: "10:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "10:00",
        endTime: "11:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "11:00",
        endTime: "12:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "12:00",
        endTime: "13:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "13:00",
        endTime: "14:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "14:00",
        endTime: "15:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "15:00",
        endTime: "16:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "16:00",
        endTime: "17:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "17:00",
        endTime: "18:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "18:00",
        endTime: "19:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "19:00",
        endTime: "20:00",
        timeSlotType: "AVAILABLE",
    },
    {
        startTime: "20:00",
        endTime: "21:00",
        timeSlotType: "AVAILABLE",
    },
];
