import { Route, Routes } from "react-router";
import { ChimeApp } from "./ChimeAppContainer";
import { RootContainer } from "./RootContainer";

export const HomeContainer = () => {
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        position: "relative",
        background: "#F3F3F3",
      }}
    >
      <Routes>
        <Route
          path={
            "/clients/:clientId/applications/:applicationId/interviewRounds/:interviewRoundId/join"
          }
          element={<ChimeApp />}
        />
        <Route path="*" element={<RootContainer />} />
        <Route index element={<RootContainer />} />
      </Routes>
    </div>
  );
};
