"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobDetailDataFetcher = void 0;
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const react_router_1 = require("react-router");
const GetJobServiceDao_1 = require("./GetJobServiceDao");
const useJobProfile = (clientId, jobProfileId) => {
    return (0, ui_core_framework_1.useQuery)(new GetJobServiceDao_1.GetJobServiceDao(clientId, jobProfileId));
};
const JobDetailDataFetcher = () => {
    const { clientId, jobProfileId } = (0, react_router_1.useParams)();
    const jobProfile = useJobProfile(clientId, jobProfileId);
    const navigate = (0, react_router_1.useNavigate)();
    return {
        jobProfile: jobProfile,
        onBackPress: () => navigate(-1),
    };
};
exports.JobDetailDataFetcher = JobDetailDataFetcher;
