import { faker } from "@faker-js/faker";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
import moment from "moment";
import { UserAvailabilitySlotType } from "@idsk/components-props";

export function getUserAvailabilityNew(): any {
  return {
    interviewSlotSelectorValues: [
      {
        timeZone: "Asia/Kolkata",
        date: moment(),
        timeSlots: [
          {
            startTime: "09:00",
            endTime: "10:00",
            timeSlotType: UserAvailabilitySlotType.AVAILABLE,
          },
          {
            startTime: "10:00",
            endTime: "11:00",
            timeSlotType: UserAvailabilitySlotType.AVAILABLE,
          },
          {
            startTime: "11:00",
            endTime: "12:00",
            timeSlotType: UserAvailabilitySlotType.AVAILABLE,
          },
          {
            startTime: "12:00",
            endTime: "13:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
          {
            startTime: "13:00",
            endTime: "14:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
          {
            startTime: "14:00",
            endTime: "15:00",
            timeSlotType: UserAvailabilitySlotType.RESERVED,
          },
          {
            startTime: "15:00",
            endTime: "16:00",
            timeSlotType: UserAvailabilitySlotType.RESERVED,
          },
          {
            startTime: "16:00",
            endTime: "17:00",
            timeSlotType: UserAvailabilitySlotType.RESERVED,
          },
          {
            startTime: "17:00",
            endTime: "18:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
          {
            startTime: "18:00",
            endTime: "19:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
          {
            startTime: "19:00",
            endTime: "20:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
          {
            startTime: "20:00",
            endTime: "21:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
        ],
      },
    ],
    timeSlots: [
      {
        timeZone: "Asia/Kolkata",
        date: moment().add(1, "day"),
        timeSlots: [
          {
            startTime: "09:00",
            endTime: "10:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
          {
            startTime: "09:00",
            endTime: "10:00",
            timeSlotType: UserAvailabilitySlotType.PROVIDED,
          },
        ],
      },
    ],
  };
}
export const mockGetUserAvailabilityNew: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const sampleData = schema.all("userAvailability").models;
  return new Response(200, {}, sampleData[0].attrs);
};

export const mockPutUserAvailability: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let UserAvailability = JSON.parse(request.requestBody);
  schema.create("userAvailability", {});
  return new Response(200, UserAvailability, "SUCCESS");
};
