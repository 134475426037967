import { ThemeProvider } from "styled-components";
import { THEME } from "./theme";
import { HomeContainer } from "./container/HomeContainer";
import { UnAuthRootContainer } from "./container/UnAuthRootContainer";

import { Amplify, Auth, Hub } from "aws-amplify";
import { amplifyConfig } from "./cognito/cognitoconfig";
import { getListener } from "./cognito/cognitoEventHandlers";
import { useEffect, useState } from "react";
import { createUserContextFromCognito } from "./cognito/cognitoUtils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { isUnAuth } from "@idsk/b2c-common";
import { UserContext } from "@idsk/ui-core-framework";
Amplify.configure(amplifyConfig);
const queryClient = new QueryClient();

function App() {
  const defaultUser = process.env.SKIP_LOGIN ? "" : null;

  const [user, setUser] = useState(defaultUser);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isUnAuth(pathname)) {
      const listener = getListener(setUser);
      Hub.listen("auth", listener);
      Auth.currentAuthenticatedUser()
        .then((currentUser) => {
          setUser(currentUser);
          localStorage.setItem(
            "idToken",
            `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
          );
        })
        .catch((err) => {
          console.log(
            "Error occured, while trying to get authenticated user. Probably the user is not logged in",
            err
          );
          if (user === null) {
            Auth.federatedSignIn();
          }
        });
    }
  }, []);

  let values = createUserContextFromCognito(
    user,
    amplifyConfig.Auth.userPoolWebClientId
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={THEME}>
        <UserContext.Provider value={values}>
          {
            (() => {
              if (isUnAuth(pathname)) {
                return <UnAuthRootContainer />;
              } else {
                return user == null ? <></> : <HomeContainer />;
              }
            })() // IIFE
          }
        </UserContext.Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
