"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobProfileInterviewerConsensusListingDataFetcher = exports.useJobProfileInterviewerConsensusPatchHook = exports.usePostHook = exports.useJobProfileInterviewerConsensusGetHook = void 0;
const components_ui_1 = require("@idsk/components-ui");
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const jobprofile_interviewer_consensus_app_1 = require("@idsk/jobprofile-interviewer-consensus-app");
const react_1 = require("react");
_a = (0, ui_core_framework_1.generateD2SCRUDHooks)("jobprofileinterviewerconsensuses", "jobprofiles", (0, ui_core_framework_1.getAxiosInstance)(), false), exports.useJobProfileInterviewerConsensusGetHook = _a.useGetHook, exports.usePostHook = _a.usePostHook, exports.useJobProfileInterviewerConsensusPatchHook = _a.usePatchHook;
const JobProfileInterviewerConsensusListingDataFetcher = (props) => {
    const initialValue = {
        clientIds: [],
        jobProfileIds: [],
        servicesRequested: "IAAS",
        belongsTo: "My",
    };
    const [filterValues, setFilterValues] = (0, react_1.useState)(initialValue);
    const listAsyncUI = (0, ui_core_framework_1.useMultiListingQuery)(jobprofile_interviewer_consensus_app_1.JobProfileInterviewerConsensusListingDao, 1, 10, [
        Object.assign({ queryId: "assigned", statuses: ["ASSIGNED_TO_INTERVIEWER"] }, filterValues),
        Object.assign({ queryId: "interviewerAccepted", statuses: [
                "ACCEPTED_BY_INTERVIEWER",
                "VERIFIED_BY_COORDINATOR",
                "DENIED_BY_COORDINATOR",
                "ACCEPTED_BY_RECRUITER",
                "DENIED_BY_RECRUITER",
            ] }, filterValues),
        Object.assign({ queryId: "interviewerDenied", statuses: ["DENIED_BY_INTERVIEWER"] }, filterValues),
    ], (r) => r, ([page, size, queryParams]) => [page, size, "all", queryParams]);
    const patchHook = (0, exports.useJobProfileInterviewerConsensusPatchHook)();
    const patchAsyncUI = (0, components_ui_1.useAsyncPutUI)(patchHook);
    return {
        assigned: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.assigned),
        interviewerAccepted: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.interviewerAccepted),
        interviewerDenied: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.interviewerDenied),
        onSearch: (schedulesListingSearch) => setFilterValues(schedulesListingSearch),
        patchAsyncUI: patchAsyncUI,
    };
};
exports.JobProfileInterviewerConsensusListingDataFetcher = JobProfileInterviewerConsensusListingDataFetcher;
