import { JobProfileInterviewerConsensusRoutes } from "@idsk/b2c-job-profile-interviewer-consensus-app";
import { CandidateListingFeedbackTrackerRoutes } from "@idsk/b2c-interviews-by-feedback-listing-app";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router";
import { NotFound } from "../common-pages/NotFound.layout";
import { JobProfileRoutes } from "@idsk/b2c-job-profile-app";
// import { ChimeApp } from "../container/ChimeAppContainer";
import { CodeCollabApp } from "../container/CodeCollabContainer";
import { QuestionBankRoutes } from "@idsk/questionbank";
import { FrequentlyAskedQuestionsRoutes } from "@idsk/faq-app";
import { UserGuideRoutes } from "@idsk/userguide-app";
import { AuthFeedbackRoutes } from "@idsk/feedback-app";
import { InterviewFeedbackRoutes } from "@idsk/interviewer-feedback-app";
import { UserAvailabilitySlotPage } from "@idsk/application-app";
import { ProfileRoutes } from "@idsk/interviewer-profile-app";

const UserAvailabilityView = () => {
  const { interviewerId, clientId } = useParams();
  return (
    <>
      <h1>My Availability</h1>
      <br />
      <UserAvailabilitySlotPage
        id={interviewerId!}
        clientId={clientId!}
        onCancel={() => {}}
        userType={"interviewers"}
      />
    </>
  );
};
export const RootRouter = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route index element={<Navigate to="interviews" replace={true} />} />
      <Route path="/interviews/*" element={<InterviewFeedbackRoutes />} />
      <Route
        path="/jobs/*"
        element={<JobProfileInterviewerConsensusRoutes />}
      />
      <Route
        path="/feedbacks/*"
        element={<CandidateListingFeedbackTrackerRoutes />}
      />
      <Route path="/profile/*" element={<ProfileRoutes />} />
      <Route path="/job-profile/*" element={<ProfileRoutes />} />
      <Route path="/clients/:clientId/jobprofiles/:jobProfileId">
        <Route path=":tabId" element={<JobProfileRoutes />} />
      </Route>
      <Route
        path="/clients/:clientId/applications/:applicationId/feedbacks/*"
        element={<AuthFeedbackRoutes />}
      />
      {/* <Route
        path={
          "/clients/:clientId/applications/:applicationId/interviewRounds/:interviewRoundId/join"
        }
        element={<ChimeApp />}
      /> */}
      <Route
        path={
          "/clients/:clientId/applications/:applicationId/interviewRounds/:interviewRoundId/onlineide/view"
        }
        element={<CodeCollabApp />}
      />
      <Route
        path="/clients/:clientId/interviewers/:interviewerId/availibility"
        element={<UserAvailabilityView />}
      />
      <Route
        path="/clients/:clientId/questions/*"
        element={<QuestionBankRoutes />}
      />
      <Route
        path="/frequentlyAskedQuestions/*"
        element={<FrequentlyAskedQuestionsRoutes />}
      />
      <Route path="/userGuide" element={<UserGuideRoutes />} />
      <Route
        path="*"
        element={
          <NotFound
            btnProps={{
              onSubmitClick: () => navigate("/", { replace: true }),
              text: "Back Home",
              icon: null,
            }}
          />
        }
      />
    </Routes>
  );
};
