export const region = "ap-south-1";
export const mandatorySignIn = true;
export const scope = ["email", "openid", "profile"];
export const responseType = "code";

export const localhost = "localhost";
export const devAppLink = "dev-tp.interviewdesk.in";
export const preProdAppLink = "pp-tp.interviewdesk.in";
export const prodAppLink = "portal.interviewdesk.in";

export const dev = "development";
export const preprod = "preprod";
export const prod = "prod";

export const localRedirectSignIn = "http://localhost:3002/";
export const devRedirectSignIn = "https://dev-tp.interviewdesk.in/";
export const preProdRedirectSignIn = "https://pp-tp.interviewdesk.in/";
export const prodRedirectSignIn = "https://portal.interviewdesk.in/";

export const baseOriginUrls = {
  [dev]: devRedirectSignIn,
  [preprod]: preProdRedirectSignIn,
  [prod]: prodRedirectSignIn,
};

export const redirectBaseUrls = {
  [dev]:
    "https://auth.dev-tp.interviewdesk.in/login?client_id=4vtio4402o0g157o9760psea54&response_type=code&scope=email+openid+profile&redirect_uri=",
  [preprod]:
    "https://idsk-coreinfracdkpreprodwave-preprod-b2c.auth.ap-south-1.amazoncognito.com/login?client_id=1mnm7cumt59nj8a8in50e5l8pt&response_type=code&scope=email+openid+profile&redirect_uri=",
  [prod]:
    "https://auth.portal.interviewdesk.in/login?client_id=1dd690s6t86cf5lckim2npiq6c&response_type=code&scope=email+openid+profile&redirect_uri=",
};

export type redirectBaseUrlsType = keyof typeof redirectBaseUrls;
export type baseOriginUrlsType = keyof typeof baseOriginUrls;
