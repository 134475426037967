import { useParams } from "react-router-dom";
import { CodeEditorMainPage } from "@idsk/code-collab-app";

export const CodeCollabApp = () => {
  const { clientId, applicationId, interviewRoundId } = useParams();
  return (
    <CodeEditorMainPage
      clientId={clientId!}
      applicationId={applicationId!}
      interviewRoundId={interviewRoundId!}
      authToken={""}
      readOnly={true}
    />
  );
};
