"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationListingLayout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_ui_1 = require("@idsk/components-ui");
const antd_1 = require("antd");
const react_1 = require("react");
const tableFilterColumns = [
    "candidateName",
    "companyName",
    "title",
    "interviewDateTime",
    "action",
];
const ApplicationListingLayout = (props) => {
    var _a, _b, _c, _d, _e;
    const tabs = [
        {
            key: "stronglyRecommended",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Strongly Recommended", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_a = props.stronglyRecommendedListAsyncUI.getResponse()) === null || _a === void 0 ? void 0 : _a.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.stronglyRecommendedListAsyncUI.Wrapper, { children: (0, jsx_runtime_1.jsx)(components_ui_1.InterviewerFeedbackView, Object.assign({}, props.stronglyRecommendedListAsyncUI.getResponse(), { filterColumns: tableFilterColumns })) })),
        },
        {
            key: "recommended",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Recommended", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_b = props.recommendedListAsyncUI.getResponse()) === null || _b === void 0 ? void 0 : _b.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.recommendedListAsyncUI.Wrapper, { children: (0, jsx_runtime_1.jsx)(components_ui_1.InterviewerFeedbackView, Object.assign({}, props.recommendedListAsyncUI.getResponse(), { filterColumns: tableFilterColumns })) })),
        },
        {
            key: "partiallyRecommended",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Partially", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_c = props.partiallyListAsyncUI.getResponse()) === null || _c === void 0 ? void 0 : _c.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.partiallyListAsyncUI.Wrapper, { children: (0, jsx_runtime_1.jsx)(components_ui_1.InterviewerFeedbackView, Object.assign({}, props.partiallyListAsyncUI.getResponse(), { filterColumns: tableFilterColumns })) })),
        },
        {
            key: "notRecommended",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Not Recommended", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_d = props.notRecommendedListAsyncUI.getResponse()) === null || _d === void 0 ? void 0 : _d.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.notRecommendedListAsyncUI.Wrapper, { children: (0, jsx_runtime_1.jsx)(components_ui_1.InterviewerFeedbackView, Object.assign({}, props.notRecommendedListAsyncUI.getResponse(), { filterColumns: tableFilterColumns })) })),
        },
        {
            key: "stronglyNotRecommended",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Strongly Not Recommended", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_e = props.stronglyNotRecommendedListAsyncUI.getResponse()) === null || _e === void 0 ? void 0 : _e.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.stronglyNotRecommendedListAsyncUI.Wrapper, { children: (0, jsx_runtime_1.jsx)(components_ui_1.InterviewerFeedbackView, Object.assign({}, props.stronglyNotRecommendedListAsyncUI.getResponse(), { filterColumns: tableFilterColumns })) })),
        },
    ];
    const rounds = 3;
    const candidateName = {
        name: "Candidate Name",
        id: "name",
        type: "string",
        value: "",
    };
    const [activeKey, setActiveKey] = (0, react_1.useState)("my");
    const fieldList = [];
    // !comment this once BE is ready
    // fieldList.push(predefinedFilterBarParams.clients);
    // fieldList.push(predefinedFilterBarParams.jobs);
    // fieldList.push(candidateName);
    // const onSearch = (values: FilterBarResult) => {
    //   const searchParams: ApplicationListingSearchType = {
    //     clientIds: values.clientsFilter as string[],
    //     jobProfileIds: values.jobsFilter as string[],
    //     name: values.name as string,
    //     belongsTo: values.belongsTo as string,
    //     servicesRequested: "IAAS",
    //   };
    //   props.onSearch(searchParams);
    // };
    return ((0, jsx_runtime_1.jsxs)(components_ui_1.IdskTabsTableContainer, { children: [(0, jsx_runtime_1.jsx)("span", { style: { fontSize: "20px" }, children: "My Feedbacks" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("div", Object.assign({ style: { background: "#fff", padding: "24px" } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Tabs, { items: tabs, defaultActiveKey: activeKey, onTabClick: (key) => setActiveKey(key) }) }))] }));
};
exports.ApplicationListingLayout = ApplicationListingLayout;
