import { UnAuthRouter } from "../router/UnAuthRouter";
export const UnAuthRootContainer = () => {
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        position: "relative",
        background: "#F3F3F3",
      }}
    >
      <UnAuthRouter />
    </div>
  );
};
