"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertFormTypeToInterviewer = exports.convertInterviewerToFormType = void 0;
const components_props_1 = require("@idsk/components-props");
const config_1 = require("../config");
const convertInterviewerToFormType = (values) => {
    const inputValues = Object.assign({}, values);
    inputValues.resume = (0, config_1.getLoadableAssetsLinkFromPath)(values.resume);
    return inputValues;
};
exports.convertInterviewerToFormType = convertInterviewerToFormType;
const convertFormTypeToInterviewer = (values) => {
    const outputValues = Object.assign({}, values);
    outputValues.summary = (0, components_props_1.safeBase64Encode)(values.summaryInPlainText);
    outputValues.pastInterviews = Number(values.pastInterviews);
    outputValues.resume = (0, components_props_1.extractFileLinks)(values.resume)[0];
    delete outputValues.summaryInPlainText;
    return Object.assign(Object.assign({}, values), outputValues);
};
exports.convertFormTypeToInterviewer = convertFormTypeToInterviewer;
