import { faker } from "@faker-js/faker";
import {
  InterviewerProfileSummaryB2C,
  InterviewProfileSummaryContentProps,
} from "@idsk/components-props";
import { RouteHandler } from "miragejs/server";
import { Registry } from "miragejs";
import { ModelRegistry } from "./MirageModels";

export function getInterviewerProfileSummaryB2CContent(): any {
  return {
    technicalSkillSummary: faker.random.words(),
    description: faker.random.words(300),
    summary: faker.random.words(400),
    expertSkills: [faker.random.words(100)],
    intermediateSkills: [faker.random.words(100)],
    beginnerSkills: [faker.random.words(100)],
    mail: faker.internet.email(),
    theme: {
      name: "Light",
      text: "#000000",
    },
  };
}

export function getInterviewerProfileSummaryB2CHeader(): any {
  return {
    profileSummaryHeader: {
      fullName: faker.name.firstName(),
      designation: faker.random.words(),
      profileActiveStatus: faker.random.words(),
      recentinterviewdate: faker.phone.number(),
    },
    profileSummaryDetails: {
      gender: faker.random.words(),
      emailId: faker.random.words(),
      mobileNumber: faker.phone.number(),
      mobileNumberPrefix: faker.phone.number(),
      alternateName: faker.random.words(),
      alternateEmailId: faker.random.words(),
      alternateMobileNumber: faker.phone.number(),
      alternateMobileNumberPrefix: faker.phone.number(),
      currentCompany: faker.random.words(),
      currentDesignation: faker.random.words(),
      yearsOfExperience: faker.datatype.number({ min: 10, max: 100 }),
      technology: faker.random.words(),
      onboardingDate: faker.random.words(),
      cancellationClause: faker.random.words(),
      cancellationPercentage: faker.datatype.number({ min: 10, max: 100 }),
    },
    profileSummaryStatus: {
      numberOfInterviewsTaken: faker.datatype.number({ min: 10, max: 100 }),
      averageRating: faker.datatype.number({ min: 1, max: 5 }),
      maxRating: faker.datatype.number({ min: 3, max: 5 }),
      numberOfClientsTaken: faker.datatype.number({ min: 10, max: 100 }),
      skills: [faker.random.words(20)],
    },
    theme: {
      name: "Light",
      text: "#000000",
    },
  };
}

export const mockGetInterviewerProfileSummaryHeaderProps: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const sampleData = schema.all("interviewerprofileSummaryList").models;
  const sampleDatacontent = schema.all(
    "interviewerprofilesummaryContent"
  ).models;

  return {
    interviewerProfileSummaryList: sampleData[0].attrs,
    interviewerProfileSummaryContent: sampleDatacontent[0].attrs,
  };
};
