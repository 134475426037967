"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserAvailability = void 0;
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const dao_1 = require("../../dao");
const react_router_dom_1 = require("react-router-dom");
const useApplicationListing = (token) => {
    return (0, ui_core_framework_1.useQuery)(new dao_1.UserAvailabilityDao(token));
};
const useUpdateUserAvailability = (token) => {
    const { response, triggerRequest } = (0, ui_core_framework_1.useMutation)(new dao_1.UpdateUserAvailabilityDao(token));
    return {
        formSubmit: triggerRequest,
        formSubmitResponse: response,
    };
};
const GetUserAvailability = () => {
    const token = (0, react_router_dom_1.useSearchParams)()[0].get("token");
    const { formSubmit, formSubmitResponse } = useUpdateUserAvailability(token ? token : "");
    return {
        onFormSubmit: formSubmit,
        userAvailability: useApplicationListing(token ? token : ""),
        token: token !== null && token !== void 0 ? token : "",
    };
};
exports.GetUserAvailability = GetUserAvailability;
