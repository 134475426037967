import { faker } from "@faker-js/faker";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
import { Registry } from "miragejs";

export function getQuestionsForListing(): any {
  const difficulty = ["Easy", "Medium", "Hard"];
  const type = ["Coding", "Multiple Choice"];
  const clients = ["idsk.internal.client.id", "self", "all"];
  return {
    clients: clients[Math.floor(Math.random() * clients.length)],
    id: faker.random.numeric(5),
    question: faker.random.words(10),
    difficulty: difficulty[Math.floor(Math.random() * difficulty.length)],
    topic: faker.random.words(8),
    concept: faker.random.words(20),
    type: type[Math.floor(Math.random() * type.length)],
    category: faker.random.words(5),
    testcases: parseInt(faker.random.numeric(1)),
    score: parseInt(faker.random.numeric(1)),
  };
}

export const mockGetQuestionsForListing: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const dbData = schema.all("question").models;
  const clients = request.queryParams?.clients ?? "";
  const filteredData = dbData.filter((clientQuestions) => {
    if (clients === (clientQuestions.attrs as any).clients) {
      return clientQuestions;
    }
  });
  return {
    total: filteredData.length,
    records: filteredData,
  };
};
