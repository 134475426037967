"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobProfileInterviewerConsensusRoutes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_ui_1 = require("@idsk/components-ui");
const react_router_1 = require("react-router");
const web_1 = require("../web");
const JobProfileInterviewerConsensusRoutes = () => {
    const navigate = (0, react_router_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)(react_router_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { index: true, element: (0, jsx_runtime_1.jsx)(web_1.JobProfileInterviewerConsensusListingPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { path: "*", element: (0, jsx_runtime_1.jsx)(components_ui_1.NotFound, { btnProps: {
                        onSubmitClick: () => navigate("/", { replace: true }),
                        text: "Back Home",
                        icon: null,
                    } }) })] }));
};
exports.JobProfileInterviewerConsensusRoutes = JobProfileInterviewerConsensusRoutes;
