"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateUserAvailabilityDao = void 0;
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const b2c_common_1 = require("@idsk/b2c-common");
class UpdateUserAvailabilityDao extends ui_core_framework_1.AsyncRequest {
    constructor(token) {
        super();
        this.token = token;
        this.getConfig = () => {
            return {
                refetchOnMount: false,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                enabled: !!this.token,
            };
        };
        this.getAsyncFunction = (application) => __awaiter(this, void 0, void 0, function* () {
            let url = `/jobseeker/availabilities/${this.token}`;
            const res = yield b2c_common_1.UnAuthAxiosInstance.put(url, application, {
                headers: { useMirage: false },
            });
            return res.data;
        });
        this.getCacheKey = () => {
            return ["UserAvailabilityRequest"];
        };
    }
}
exports.UpdateUserAvailabilityDao = UpdateUserAvailabilityDao;
