"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetJobServiceDao = void 0;
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const frontend_dao_1 = require("@idsk/frontend-dao");
class GetJobServiceDao extends ui_core_framework_1.AsyncRequest {
    constructor(clientId, jobId) {
        super();
        this.clientId = clientId;
        this.jobId = jobId;
        this.getConfig = () => {
            return {
                refetchOnMount: false,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                enabled: !!this.jobId,
            };
        };
        this.getAsyncFunction = () => __awaiter(this, void 0, void 0, function* () {
            // we'll use instance here.
            const res = yield frontend_dao_1.B2CAxiosInstance.get(`/clients/${this.clientId}/jobprofiles/${this.jobId}`);
            return res.data;
        });
        this.getCacheKey = () => {
            return ["getJobProfile", this.clientId, this.jobId];
        };
    }
}
exports.GetJobServiceDao = GetJobServiceDao;
