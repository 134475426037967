"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandidateListingFeedbackTrackerRoutes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const react_router_1 = require("react-router");
const NotFound_layout_1 = require("../web/common-pages/NotFound.layout");
const ApplicationListing_df_1 = require("../web/application-listing/ApplicationListing.df");
const ApplicationListing_layout_1 = require("../web/application-listing/ApplicationListing.layout");
const CandidateListingFeedbackTrackerRoutes = () => {
    const CandidateListingPage = (0, ui_core_framework_1.attachHandlers)("CandidateListing")(ApplicationListing_df_1.ApplicationListingDataFetcher)(ApplicationListing_layout_1.ApplicationListingLayout);
    const navigate = (0, react_router_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)(react_router_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { index: true, element: (0, jsx_runtime_1.jsx)(CandidateListingPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { path: "*", element: (0, jsx_runtime_1.jsx)(NotFound_layout_1.NotFound, { btnProps: {
                        onSubmitClick: () => navigate("/", { replace: true }),
                        text: "Back Home",
                        icon: null,
                    } }) })] }));
};
exports.CandidateListingFeedbackTrackerRoutes = CandidateListingFeedbackTrackerRoutes;
