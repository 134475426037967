import { Route, Routes, useNavigate } from "react-router";
import { NotFound } from "../common-pages/NotFound.layout";
import { OnlineCompilerRoutes } from "@idsk/b2c-online-compiler-app";
import { UnAuthUrlPrefix } from "@idsk/b2c-common";
import { UserAvailabilityRoutes } from "@idsk/b2c-unauth-user-availability-app";
import { ChimeApp } from "../container/ChimeAppContainer";

export const UnAuthRouter = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route
        path={UnAuthUrlPrefix + "slot/*"}
        element={<UserAvailabilityRoutes />}
      />
      <Route
        path={UnAuthUrlPrefix + "ide/*"}
        element={<OnlineCompilerRoutes />}
      />
      <Route path={UnAuthUrlPrefix + "meeting/*"} element={<ChimeApp />} />
      <Route
        path="*"
        element={
          <NotFound
            btnProps={{
              onSubmitClick: () => navigate("/", { replace: true }),
              text: "Back Home",
              icon: null,
            }}
          />
        }
      />
    </Routes>
  );
};
