import { EnvironmentsType } from "@idsk/frontend-dao";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "@idsk/components-assets/es5/theme.less";
import { makeServer } from "./mirage";
const environment = process.env.STAGE as EnvironmentsType;

if (environment === "development") {
  makeServer();
}

window.env = {
  STAGE: process.env.STAGE,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
