"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationListingDataFetcher = void 0;
const components_ui_1 = require("@idsk/components-ui");
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const react_1 = require("react");
const react_router_1 = require("react-router");
const Application_dao_1 = require("./Application.dao");
const ApplicationListingDataFetcher = () => {
    const navigate = (0, react_router_1.useNavigate)();
    const initialValue = {
        clientIds: [],
        jobProfileIds: [],
        name: "",
        belongsTo: ["My"],
        servicesRequested: "IAAS",
    };
    const [filterValues, setFilterValues] = (0, react_1.useState)(initialValue);
    const listAsyncUI = (0, ui_core_framework_1.useMultiListingQuery)(Application_dao_1.ApplicationsListingDao, 1, 10, [
        Object.assign({ queryId: "stronglyRecommended", interviewFeedbacks: [
                "R1: Strongly Recommended",
                "R2: Strongly Recommended",
                "R3: Strongly Recommended",
            ] }, filterValues),
        Object.assign({ queryId: "recommended", interviewFeedbacks: [
                "R1: Recommended",
                "R2: Recommended",
                "R3: Recommended",
            ] }, filterValues),
        Object.assign({ queryId: "partially", interviewFeedbacks: ["R1: Partially", "R2: Partially", "R3: Partially"] }, filterValues),
        Object.assign({ queryId: "notRecommended", interviewFeedbacks: [
                "R1: Not Recommended",
                "R2: Not Recommended",
                "R3: Not Recommended",
            ] }, filterValues),
        Object.assign({ queryId: "stronglyNotRecommended", interviewFeedbacks: [
                "R1: Strongly Not Recommended",
                "R2: Strongly Not Recommended",
                "R3: Strongly Not Recommended",
            ] }, filterValues),
    ], (r) => r, ([page, size, queryParams]) => [page, size, "all", queryParams]);
    const onRequestAvailability = (id, clientIdFromOnSlot, jobProfileId) => {
        navigate(`/clients/${clientIdFromOnSlot}/jobs/${jobProfileId}/candidates/${id}/profile`);
    };
    return {
        stronglyRecommendedListAsyncUI: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.stronglyRecommended),
        recommendedListAsyncUI: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.recommended),
        partiallyListAsyncUI: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.partially),
        notRecommendedListAsyncUI: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.notRecommended),
        stronglyNotRecommendedListAsyncUI: (0, components_ui_1.useAsyncGetUI)(listAsyncUI.stronglyNotRecommended),
        onSearch: (schedulesListingSearch) => setFilterValues(schedulesListingSearch),
        initialListingSearchValues: initialValue,
        onRequestAvailability,
        onRequestCandidateNotesClick: (clientId, jobProfileId, id) => {
            navigate(`/candidates/clients/${clientId}/jobProfiles/${jobProfileId}/applications/${id}/candidate`);
        },
    };
};
exports.ApplicationListingDataFetcher = ApplicationListingDataFetcher;
