import { faker } from "@faker-js/faker";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
import { InterviewRoundStatus } from "@idsk/components-props";

const status = [
  InterviewRoundStatus.INTERVIEW_SCHEDULED,
  InterviewRoundStatus.INTERVIEW_COMPLETED,
  InterviewRoundStatus.FEEDBACK_PROVIDED,
];
const random = Math.floor(Math.random() * status.length);
export function getInterview(): any {
  return {
    interviewerId: faker.datatype.uuid(),
    candidateName: faker.name.firstName(),
    resumeLink: faker.random.alpha(6),
    companyName: faker.company.name(),
    title: faker.name.jobTitle(),
    interviewMode: faker.random.alpha({ count: 10 }),
    interviewDateTime: faker.date.past(),
    status: InterviewRoundStatus.INTERVIEW_COMPLETED,
    candidateNotes: faker.random.words(),
  };
}
export const mockGetInterview: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const sampleData = schema.all("application").models;
  const qp = request.queryParams;
  const from = parseInt(qp?.from ?? "0");
  const size = parseInt(qp?.size ?? "10");
  console.log("applications", sampleData.slice(from, from + size));
  return {
    interviewCompleted: {
      count: sampleData.length,
      interviewList: sampleData.slice(from, from + size),
    },
    feedbackProvided: {
      count: sampleData.length,
      interviewList: sampleData.slice(from, from + size),
    },
    interviewScheduled: {
      count: sampleData.length,
      interviewList: sampleData.slice(from, from + size),
    },
  };
};

export const mockPatchCandidate: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let candidateNotes = JSON.parse(request.requestBody);
  const dbData = schema.all("application").models;
  const candidate = dbData.find(
    (item) => (item?.attrs as any)?.id === request.params.id
  );

  if (candidate) {
    candidate.update(candidateNotes);
    return new Response(200, {}, { status: "SUCCESS" });
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};
