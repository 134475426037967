import { faker } from "@faker-js/faker";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
import { JobProfileV2, bufferEncode } from "@idsk/components-props";

export function getJobProfileV2Calibration(): JobProfileV2 {
  const education = ["Diploma", "Bachelor", "Master"];
  const jobType = ["Full-Time", "Part-Time", "Contract", "Intern"];
  const roleMaturity = ["Senior", "Mid", "Junior"];
  const createMockFeedbackData = (number: number) => {
    let data: any = {};
    for (let i = 0; i < number + 1; i++) {
      data[`R${i + 1}`] = faker.datatype.number(12);
    }
    return data;
  };

  return {
    displayId: faker.datatype.number({ min: 1, max: 40 }).toString(),
    title: faker.name.jobTitle(),
    department: faker.name.jobArea(),
    description: faker.name.jobDescriptor(),
    education:
      education[faker.datatype.number({ min: 0, max: 2, precision: 1 })],
    experienceRange: {
      start: faker.datatype.number({ min: 1, max: 6, precision: 1 }),
      end: faker.datatype.number({ min: 7, max: 12, precision: 1 }),
    },
    jobType: jobType[faker.datatype.number({ min: 0, max: 3, precision: 1 })],
    location: [`${faker.address.cityName()},${faker.address.country()}`],
    numberOfOpening: faker.datatype.number({ min: 2, max: 10, precision: 1 }),
    qualifications: faker.datatype
      .array(12)
      .map(() => faker.lorem.paragraph(10)),
    responsibilities: faker.datatype
      .array(9)
      .map(() => faker.lorem.paragraph(10)),
    roleMaturity:
      roleMaturity[faker.datatype.number({ min: 0, max: 2, precision: 1 })],
    reasonForHiring: faker.random.words(3),
    remoteAssurance: faker.datatype.boolean(),
    salaryRange: {
      start: faker.datatype.number({
        min: 180000,
        max: 800000,
        precision: 10000,
      }),
      end: faker.datatype.number({
        min: 180000,
        max: 800000,
        precision: 10000,
      }),
    },
    skillSets: faker.datatype
      .array(14)
      .map(() => faker.company.catchPhraseAdjective()),
    noOfApplications: faker.datatype.number(40),
    feedbackProvided: createMockFeedbackData(
      faker.datatype.number({ min: 2, max: 3 })
    ),
    interviewRecommended: createMockFeedbackData(
      faker.datatype.number({ min: 2, max: 3 })
    ),
    id: faker.datatype.uuid(),
    clientId: "idsk.id.saju.client",
    calibrationNotes: bufferEncode(faker.random.words()),
  };
}

export const mockPatchJobProfileV2: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let CalibrationNotes = JSON.parse(request.requestBody);
  const dbData = schema.all("calibrationNotesList").models;
  const application = dbData.find(
    (item) => (item?.attrs as any)?.id === request.params.id
  );
  if (application) {
    application.update(CalibrationNotes);
    return new Response(200, {}, "SUCCESS");
  } else {
    return new Response(404, {}, { errMessage: "Not Found" });
  }
};
