"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobProfileRoutes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const react_router_1 = require("react-router");
const jobDetail_df_1 = require("../web/job-details/jobDetail.df");
const JobDetail_layout_1 = require("../web/job-details/JobDetail.layout");
const components_ui_1 = require("@idsk/components-ui");
const JobProfileRoutes = () => {
    const JobDetailPage = (0, ui_core_framework_1.attachHandlers)("JobDetail")(jobDetail_df_1.JobDetailDataFetcher)(JobDetail_layout_1.JobDetailLayout);
    const navigate = (0, react_router_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)(react_router_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { index: true, element: (0, jsx_runtime_1.jsx)(JobDetailPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { path: "*", element: (0, jsx_runtime_1.jsx)(components_ui_1.NotFound, { btnProps: {
                        onSubmitClick: () => navigate("/", { replace: true }),
                        text: "Back Home",
                        icon: null,
                    } }) })] }));
};
exports.JobProfileRoutes = JobProfileRoutes;
