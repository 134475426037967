import {
  dev,
  preprod,
  redirectBaseUrls,
  prod,
  baseOriginUrls,
  redirectBaseUrlsType,
} from "./constants";
import jwt_decode from "jwt-decode";
import { UserContextObject } from "@idsk/ui-core-framework";

function getHttp(stage: redirectBaseUrlsType) {
  const originUrl = window?.location?.origin;
  return originUrl ? `${originUrl}/` : baseOriginUrls[stage];
}

export function getRedirectUrls(stage: redirectBaseUrlsType) {
  const baseOriginUrl = getHttp(stage ? stage : prod);

  const redirectUrlOnSignout =
    // @ts-ignore
    redirectBaseUrls[stage || prod] + baseOriginUrl;

  switch (stage) {
    case dev: {
      return {
        domain: redirectUrlOnSignout.split("/")[2],
        userPoolId: "ap-south-1_PSXp5oT19",
        userPoolWebClientId: "4vtio4402o0g157o9760psea54",
        redirectSignIn: baseOriginUrl,
        redirectSignOut: redirectUrlOnSignout,
      };
    }
    case preprod: {
      return {
        domain: redirectUrlOnSignout.split("/")[2],
        userPoolId: "ap-south-1_VfdkPCzvf",
        userPoolWebClientId: "1mnm7cumt59nj8a8in50e5l8pt",
        redirectSignIn: baseOriginUrl,
        redirectSignOut: redirectUrlOnSignout,
      };
    }
    default: {
      // By default always point to prod
      return {
        domain: redirectUrlOnSignout.split("/")[2],
        userPoolId: "ap-south-1_6f2MszS2r",
        userPoolWebClientId: "1dd690s6t86cf5lckim2npiq6c",
        redirectSignIn: baseOriginUrl,
        redirectSignOut: redirectUrlOnSignout,
      };
    }
  }
}

export function createUserContextFromCognito(
  user: any,
  webClientId: string
): UserContextObject {
  if (user === null || user === "") {
    return {
      mail: "",
      idToken: "",
      isSignedIn: false,
      clientId: "",
      id: "",
    };
  }
  let key = `CognitoIdentityServiceProvider.${webClientId}.${user.username}.idToken`;
  const idToken = user.storage[key];
  if (idToken === undefined) {
    return { mail: "", idToken: "", isSignedIn: false, clientId: "" };
  }
  let decodedToken: any = jwt_decode(idToken);
  const mail = decodedToken.email;
  const clientId = decodedToken["custom:idskClientId"];
  const id = decodedToken["custom:idskUserId"];
  return { idToken, mail, isSignedIn: true, clientId, id };
}
