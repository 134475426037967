import { SideBarItem } from "@idsk/components-props";
import { Header, SideBar } from "@idsk/components-ui";
import { RootRouter } from "../router/Router";
import { THEME } from "../theme";
import { Layout } from "antd";
import { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import {
  InterviewIcon,
  CalendarIcon,
  LogoInterviewer,
  ProfileIcon,
  FAQQuestionIcon,
} from "@idsk/components-assets";
import { useLocation, useNavigate, useParams } from "react-router";
import { FileTextOutlined } from "@ant-design/icons";
import { UserContext } from "@idsk/ui-core-framework";

const onContactClick = () => {
  window.open("mailto:contact@interviewdesk.in");
};
const onLogoClick = () => {};

const onSignOutClick = () => {
  Auth.signOut()
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
};

export const RootContainer = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { mail, clientId, id } = useContext(UserContext);
  const navigate = useNavigate();

  const onSideBarItemClick = (
    sectionId: string,
    sideBarItem: SideBarItem
  ): void => {
    sideBarItem.id === "availability"
      ? navigate(`/clients/${clientId}/interviewers/${id}/availibility`)
      : navigate(sideBarItem.id);
  };

  const location = useLocation();

  const selectedSideBarTab = location.pathname.split("/")[1];
  const items = [
    {
      id: "/interviews",
      title: "INTERVIEW",
      data: menu(mail),
    },
    {
      id: "/profile",
      title: "PROFILE",
      data: [
        {
          icon: ProfileIcon,
          id: "/profile",
          title: "My Profile",
        },
      ],
    },
    {
      id: "faq",
      title: "FAQ",
      data: [
        {
          icon: FAQQuestionIcon,
          id: "/frequentlyAskedQuestions",
          title: "FAQ",
        },
      ],
    },
    {
      id: "userguide",
      title: "Interviewer Guide",
      data: [
        {
          icon: FileTextOutlined,
          id: "/userguide",
          title: "Interviewer Guide",
        },
      ],
    },
  ];

  const ProfileObject = {
    displayName: mail.split("@")[0],
    avatar: mail.split("@")[0],
  };
  enum NavMenuIconState {
    EXPANDED,
    COLLAPSED,
    DISABLED,
  }

  const onNavMenuIconClick = (a: NavMenuIconState) => {
    setCollapsed(a === NavMenuIconState.COLLAPSED);
  };
  const { Content } = Layout;
  return (
    <Layout>
      <SideBar
        collapsed={collapsed}
        onItemClick={onSideBarItemClick}
        items={items}
        onContactClick={onContactClick}
        selectedItemId={selectedSideBarTab}
        theme={THEME}
        onLogoClick={onLogoClick}
        logo={LogoInterviewer}
      />
      <Layout>
        <Header
          onSignOutClick={onSignOutClick}
          onNavMenuIconClick={onNavMenuIconClick}
          profile={ProfileObject}
          navMenuIconState={NavMenuIconState.EXPANDED}
          theme={THEME}
        />
        <Content
          style={{
            margin: "24px 16px",
            height: "0px",
            overflow: "auto",
          }}
        >
          <RootRouter />
        </Content>
      </Layout>
    </Layout>
  );
};

function menu(mail: string) {
  const myInterviews = {
    icon: InterviewIcon,
    id: "/interviews",
    title: "My Interviews",
  };

  const myAvailability = {
    icon: FileTextOutlined,
    id: "availability",
    title: "My Availability",
  };

  const myJobs = {
    icon: FileTextOutlined,
    id: "jobs",
    title: "My Jobs",
  };

  const feedbacks = {
    icon: FileTextOutlined,
    id: "feedbacks",
    title: "My Feedbacks",
  };

  if (mail.includes("interviewdesk.in")) {
    //todo: use this list for hiding the features.
    return [myInterviews, myJobs, myAvailability, feedbacks];
  } else {
    return [myInterviews, myJobs, myAvailability, feedbacks];
  }
}
