"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAvailabilityDao = void 0;
const ui_core_framework_1 = require("@idsk/ui-core-framework");
const b2c_common_1 = require("@idsk/b2c-common");
class UserAvailabilityDao extends ui_core_framework_1.AsyncRequest {
    constructor(token, startDate, endDate, timeZone) {
        super();
        this.token = token;
        this.startDate = startDate;
        this.endDate = endDate;
        this.timeZone = timeZone;
        this.getConfig = () => {
            return {
                refetchOnMount: true,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                keepPreviousData: false,
                enabled: !!this.token,
            };
        };
        this.getAsyncFunction = () => __awaiter(this, void 0, void 0, function* () {
            let url = `/jobseeker/availabilities/${this.token}`;
            let params = {};
            if (!!this.startDate) {
                params["fromDate"] = this.startDate;
            }
            if (!!this.endDate) {
                params["toDate"] = this.endDate;
            }
            if (!!this.timeZone) {
                params["zoneId"] = this.timeZone;
            }
            console.log("params = ", params);
            const res = yield b2c_common_1.UnAuthAxiosInstance.get(url, {
                headers: { useMirage: false },
                params: Object.assign({}, params),
            });
            console.log("res", res);
            return res.data;
        });
        this.getCacheKey = () => {
            var _a, _b, _c;
            return [
                "getUserAvailability",
                (_a = this.startDate) !== null && _a !== void 0 ? _a : "",
                (_b = this.endDate) !== null && _b !== void 0 ? _b : "",
                (_c = this.timeZone) !== null && _c !== void 0 ? _c : "",
            ];
        };
    }
}
exports.UserAvailabilityDao = UserAvailabilityDao;
