import {
  Feedback,
  CandidateDetails,
  InterviewSlotSelectorValues,
} from "@idsk/components-props";
import { Model } from "miragejs";

export const ModelRegistry = {
  application: Model.extend<Partial<any>>({}),
  userAvailability: Model.extend<Partial<CandidateDetails>>({}),
  interviewerAvailability: Model.extend<Partial<InterviewSlotSelectorValues>>(
    []
  ),
  feedback: Model.extend<Partial<Feedback>>({}),
  interviewerProfileSummaryList: Model.extend<Partial<any>>({}),
  interviewerProfileSummaryContent: Model.extend<Partial<any>>({}),
  question: Model.extend<Partial<any>>({}),
  calibrationNotesList: Model.extend<Partial<any>>({}),
};
