"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobProfileInterviewerConsensusListingLayout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_ui_1 = require("@idsk/components-ui");
const react_1 = require("react");
const jobprofile_interviewer_consensus_app_1 = require("@idsk/jobprofile-interviewer-consensus-app");
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const tableFilterColumns = ["clientCompanyName", "jobProfileTitle"];
const JobProfileInterviewerConsensusListingLayout = (props) => {
    var _a, _b, _c;
    const tabs = [
        {
            key: "assigned",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Assigned", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_a = props.assigned.getResponse()) === null || _a === void 0 ? void 0 : _a.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.assigned.Wrapper, { children: (0, jsx_runtime_1.jsx)(jobprofile_interviewer_consensus_app_1.JobProfileInterviewerConsensusListingTableView, Object.assign({}, props.assigned.getResponse(), { patchAsyncUI: props.patchAsyncUI, filterColumns: [...tableFilterColumns, "interviewerAction"], listUI: props.assigned })) })),
        },
        {
            key: "interviewerAccepted",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Accepted", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_b = props.interviewerAccepted.getResponse()) === null || _b === void 0 ? void 0 : _b.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.interviewerAccepted.Wrapper, { children: (0, jsx_runtime_1.jsx)(jobprofile_interviewer_consensus_app_1.JobProfileInterviewerConsensusListingTableView, Object.assign({}, props.interviewerAccepted.getResponse(), { patchAsyncUI: props.patchAsyncUI, filterColumns: tableFilterColumns, listUI: props.assigned })) })),
        },
        {
            key: "interviewerDenied",
            label: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Denied", (0, jsx_runtime_1.jsx)(antd_1.Badge, { count: (_c = props.interviewerDenied.getResponse()) === null || _c === void 0 ? void 0 : _c.total, showZero: true, offset: [10, 0] })] })),
            children: ((0, jsx_runtime_1.jsx)(props.interviewerDenied.Wrapper, { children: (0, jsx_runtime_1.jsx)(jobprofile_interviewer_consensus_app_1.JobProfileInterviewerConsensusListingTableView, Object.assign({}, props.interviewerDenied.getResponse(), { patchAsyncUI: props.patchAsyncUI, filterColumns: tableFilterColumns, listUI: props.assigned })) })),
        },
    ];
    const [activeKey, setActiveKey] = (0, react_1.useState)("my");
    const fieldList = [];
    // !comment this when the BE is ready.
    // fieldList.push(predefinedFilterBarParams.clients);
    // fieldList.push(predefinedFilterBarParams.jobs);
    // const onSearch = (values: FilterBarResult) => {
    //   const searchParams: JobProfileInterviewerConsensusListingSearchType = {
    //     clientIds: values.clientsFilter as string[],
    //     jobProfileIds: values.jobsFilter as string[],
    //     servicesRequested: "IAAS",
    //     belongsTo: "My",
    //   };
    //   props.onSearch(searchParams);
    // };
    return ((0, jsx_runtime_1.jsxs)(components_ui_1.IdskTabsTableContainer, { children: [(0, jsx_runtime_1.jsx)("span", { style: { fontSize: "20px" }, children: "My Jobs" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("div", Object.assign({ style: { background: "#fff", padding: "24px" } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Tabs, { items: tabs, defaultActiveKey: activeKey, onTabClick: (key) => setActiveKey(key), tabBarExtraContent: (0, jsx_runtime_1.jsx)(antd_1.Button, { icon: (0, jsx_runtime_1.jsx)(icons_1.ReloadOutlined, {}), onClick: props.assigned.triggerRequest }) }) }))] }));
};
exports.JobProfileInterviewerConsensusListingLayout = JobProfileInterviewerConsensusListingLayout;
