"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobProfileOptions = void 0;
const components_props_1 = require("@idsk/components-props");
exports.JobProfileOptions = {
    skillSets: components_props_1.SkillSets,
    rounds: ["1", "2", "3"],
    interviewMode: components_props_1.InterviewModeDisplayString,
    experienceRange: [0, 35],
    salaryRange: [0, 99],
    department: components_props_1.DepartmentDisplayString,
    jobType: components_props_1.JobTypeDisplayString,
};
