"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationsListingDao = void 0;
const ui_core_framework_1 = require("@idsk/ui-core-framework");
class ApplicationsListingDao extends ui_core_framework_1.AsyncRequest {
    constructor(pageNumber, pageSize, clientId, queryParamsAsBody) {
        super();
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.clientId = clientId;
        this.queryParamsAsBody = queryParamsAsBody;
        this.getConfig = () => {
            return {
                refetchOnMount: true,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                keepPreviousData: false,
            };
        };
        this.getAsyncFunction = () => __awaiter(this, void 0, void 0, function* () {
            let url = `/usePostToMultiSearch/clients/${this.clientId}/applications`;
            const res = yield (0, ui_core_framework_1.getAxiosInstance)().post(url, this.queryParamsAsBody, {
                params: {
                    from: this.contentFrom,
                    size: this.pageSize,
                },
                headers: {
                    useMirage: false,
                },
            });
            return res.data;
        });
        this.getCacheKey = () => {
            return [
                "ApplicationsListing",
                this.contentFrom.toString(),
                this.pageNumber.toString(),
                this.pageSize.toString(),
                this.clientId,
                JSON.stringify(this.queryParamsAsBody),
            ];
        };
        this.contentFrom = (this.pageNumber - 1) * this.pageSize;
    }
}
exports.ApplicationsListingDao = ApplicationsListingDao;
